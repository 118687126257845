/* eslint-disable no-undefined */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
    adjust,
    append,
    evolve,
    equals,
    mergeLeft,
    remove,
    without
} from 'ramda';

import {filterPolygon, smooth} from '../drawing';

import './Notes.css';

import Pencil from '../icons/Pencil.svg';
import Trash from '../icons/Trash.svg';

const CAN_COMMENT = {comment: true, add: true, self: true, admin: true};
const CAN_ADD = {add: true, self: true, admin: true};
const CAN_EDIT_SELF = {self: true, admin: true};
const CAN_EDIT_ALL = {admin: true};

// ms delay between resize / scroll polls
const POSITIONINTERVAL = 100;
// smoothing of drawn paths - 0 is piecewise linear, 1 is fully rounded
const SMOOTHING = 1;
// px tolerance for simplifying drawn lines
const BEND_TOLERANCE = 3;
// ms delay after drawing to wait and see if we make a new shape
const MULTI_SHAPE_DELAY = 1500;
// px moved while mouse / touch is down to distinguish a draw from a click
const MIN_DRAW_SIZE = 5;

class _Note extends Component {
    constructor(props) {
        super(props);

        this.commentsEl = React.createRef();
        this.input = React.createRef();

        this.toggleOpen = this.toggleOpen.bind(this);
        this.commentKeyPress = this.commentKeyPress.bind(this);
        this.addComment = this.addComment.bind(this);
        this.quickText = this.quickText.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.updatePosition = this.updatePosition.bind(this);
        this.focusInput = this.focusInput.bind(this);

        this.state = {
            // start off displayed but invisible, so focusing works
            anchorPosition: {opacity: 0},
            commentPosition: {opacity: 0}
        };
    }

    toggleOpen() {
        const {setText, open, index} = this.props;
        setText({open: !open}, index);
    }

    commentKeyPress(e) {
        if (e.key === 'Enter' && !e.shiftKey) {
            this.addComment();
            e.preventDefault();
        }
    }

    addComment() {
        const {innerText: value} = this.input.current;
        if (value) {
            this.input.current.innerText = '';
            const {entries, setText, username, index} = this.props;
            setText({entries: append({username, value}, entries)}, index);
        }
    }

    quickText(e) {
        const {setText, username, index} = this.props;
        setText({entries: [{username, value:e.target.innerText}]}, index);
    }

    deleteNote() {
        const {setText, index} = this.props;
        setText(null, index);
    }

    updatePosition() {
        if (document.visibilityState !== 'visible') {
            return;
        }

        const setIfChanged = (newAnchorPosition, newCommentPosition) => {
            const {anchorPosition, commentPosition} = this.state;
            if (
                !equals(anchorPosition, newAnchorPosition) ||
                !equals(commentPosition, newCommentPosition)
            ) {
                this.setState({
                    anchorPosition: newAnchorPosition,
                    commentPosition: newCommentPosition
                })
            }
        }

        const hide = () => {
            setIfChanged({display: 'none'}, {display: 'none'});
        }

        // We assume the notes are absolutely positioned wrt. the body
        // TODO: what if that's not the case? ie this component is inside
        // something else that's shifting positions?
        const {
            clientHeight,
            clientWidth,
            scrollTop,
            scrollLeft
        } = document.documentElement;
        const {anchor_id, x, y} = this.props;
        const body = document.body;
        const anchorEl = anchor_id ? document.getElementById(anchor_id) : body;
        if (!anchorEl) {
            hide();
            return;
        }

        const anchorBB = anchorEl.getBoundingClientRect();

        const xVp = anchorBB.left + (x * anchorBB.width);
        const yVp = anchorBB.top + (y * anchorBB.height);
        if (xVp < 0 || xVp > clientWidth || yVp < 0 || yVp > clientHeight) {
            hide();
            return;
        }
        const xPx = Math.round(xVp + scrollLeft);
        const yPx = Math.round(yVp + scrollTop);
        // TODO: also check if the element is occluded by something else?
        // https://stackoverflow.com/a/41698614/9188800

        const newAnchorPosition = {left: xPx + 'px', top: yPx + 'px'};

        const commentsEl = this.commentsEl.current;
        const commentsBB = commentsEl ? commentsEl.getBoundingClientRect() : {};
        const commentHeight = commentsBB.height || 0;
        const newCommentPosition = {
            top: Math.min(yPx, clientHeight + scrollTop - commentHeight) + 'px'
        }
        if (xPx < clientWidth / 2) {
            newCommentPosition.left = xPx + 'px';
        }
        else {
            newCommentPosition.right = (clientWidth + scrollLeft - xPx) + 'px';
        }

        setIfChanged(newAnchorPosition, newCommentPosition);
    }

    componentDidMount() {
        this.positionInterval = setInterval(
            this.updatePosition,
            POSITIONINTERVAL
        );
        this.focusInput();
    }

    componentWillUnmount() {
        clearInterval(this.positionInterval);
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open) {
            this.focusInput();
        }
    }

    focusInput() {
        // called for new or newly-opened notes so you can just start typing
        const input = this.input.current;
        const {entries, quick_text} = this.props;
        if (input) {
            // except when there's quick text, then we don't want a mobile keyboard
            // to obscure the quick text.
            // TODO: can we only do this on mobile, still focus on desktop?
            if (entries.length || !quick_text.length) {
                input.focus();
            }
        }
    }

    render() {
        const {anchorPosition, commentPosition} = this.state;
        const {open, entries, permission, quick_text, username} = this.props;

        const canDelete = CAN_EDIT_ALL[permission] || (
            CAN_EDIT_SELF[permission] &&
            ((entries[0] || {}).username || username) === username
        );

        const canComment = CAN_COMMENT[permission];
        const firstComment = canComment && !entries.length;

        return [
            <div
                className={
                    'dash-note-anchor' + (open ? ' dash-note-anchor-open' : '')
                }
                key='anchor'
                onClick={this.toggleOpen}
                style={anchorPosition}
            />,
            open && <div
                className='dash-note-container'
                key='container'
                ref={this.commentsEl}
                style={commentPosition}
            >
                {entries.map(({username, value}, i) => (
                    <div className='dash-note-entry' key={i}>
                        {username && <div className='dash-note-username'>
                            {username}
                        </div>}
                        <div className='dash-note-text'>
                            {value}
                        </div>
                    </div>
                ))}
                {canComment && <div
                    className='dash-note-new-entry'
                    key='new'
                >
                    <div
                        contentEditable='true'
                        className='dash-note-input'
                        ref={this.input}
                        onKeyPress={this.commentKeyPress}
                    />
                    <div
                        className='dash-note-add-entry'
                        onClick={this.addComment}
                    >
                        <p>{entries.length ? 'REPLY' : 'COMMENT'}</p>
                    </div>
                </div>}
                {firstComment && <div className='dash-note-entry'>
                    {quick_text.map((value, i) => (
                        <div
                            className='dash-note-quick-text'
                            key={i}
                            onClick={this.quickText}
                        >{value}</div>
                    ))}
                </div>}
                {canDelete && <div
                    className='dash-note-delete'
                    onClick={this.deleteNote}
                >
                    <Trash />
                </div>}
            </div>
        ];
    }
}

_Note.displayName = '_Note';

_Note.propTypes = {
    index: PropTypes.number,
    setText: PropTypes.func,
    username: PropTypes.string,
    permission: PropTypes.oneOf(['view', 'comment', 'add', 'self', 'admin']),
    anchor_id: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    open: PropTypes.bool,
    entries: PropTypes.arrayOf(PropTypes.exact({
        username: PropTypes.string,
        edit_username: PropTypes.string,
        value: PropTypes.string.isRequired
    })),
    quick_text: PropTypes.arrayOf(PropTypes.string)
};

class _Shape extends Component {
    constructor(props) {
        super(props);

        this.updatePosition = this.updatePosition.bind(this);

        this.state = {
            x0: 0,
            x1: 0,
            y0: 0,
            y1: 0,
            visible: false
        }
    }

    updatePosition() {
        if (document.visibilityState !== 'visible') {
            return;
        }

        const setIfChanged = newState => {
            if (!equals(newState, this.state)) {
                this.setState(newState);
            }
        }

        const {anchor_id, canvas} = this.props;
        const body = document.body;
        const anchorEl = anchor_id ? document.getElementById(anchor_id) : body;
        if (!anchorEl) {
            setIfChanged({visible: false, x0: 0, x1: 0, y0: 0, y1: 0});
            return;
        }

        const {left: x0, top: y0} = canvas.current.getBoundingClientRect();
        const {left, right, top, bottom} = anchorEl.getBoundingClientRect();

        setIfChanged({
            visible: true,
            x0: left - x0,
            x1: right - x0,
            y0: top - y0,
            y1: bottom - y0
        });
    }

    componentDidMount() {
        this.positionInterval = setInterval(
            this.updatePosition,
            POSITIONINTERVAL
        );
    }

    componentWillUnmount() {
        clearInterval(this.positionInterval);
    }

    render() {
        const {visible, x0, x1, y0, y1} = this.state;
        if (!visible) {
            return null;
        }
        const {points} = this.props;
        const dx = x1 - x0;
        const dy = y1 - y0;
        const scale = ([x, y]) => [x0 + dx * x, y0 + dy * y];
        return <path
            className='dash-note-path'
            d={smooth(points.map(scale), SMOOTHING)}
        />;
    }
}

_Shape.displayName = '_Shape';

_Shape.propTypes = {
    index: PropTypes.number,
    canvas: PropTypes.any,
    anchor_id: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
}

/**
 * Notes allows users to add text notes and drawings to an existing dash app
 */
export default class Notes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            adding: false
        };

        this.toggleAdd = this.toggleAdd.bind(this);
        this.newText = this.newText.bind(this);
        this.setText = this.setText.bind(this);
        this.setNewShape = this.setNewShape.bind(this);
        this.normalizedPoint = this.normalizedPoint.bind(this);
        this.startShape = this.startShape.bind(this);
        this.updateShape = this.updateShape.bind(this);
        this.endShape = this.endShape.bind(this);
        this.cancelShape = this.cancelShape.bind(this);
        this.renderDrawings = this.renderDrawings.bind(this);

        this.canvas = React.createRef();
        this.newShape = React.createRef();
    }

    toggleAdd() {
        this.setState({adding: !this.state.adding});
    }

    /*
     * The implied anchor of a new shape / point is:
     * - an element with an ID
     * - that the center of the new shape is over the bbox of
     * - pick the smallest such element that the new shape isn't too much
     *   bigger than.
     * This last criterion is fuzzy. Let's start by saying neither the x extent
     * nor the y extent of the new object is more than twice the anchor extent.
     */
    findAnchor(left, right, top, bottom) {
        const x = (left + right) / 2;
        const y = (top + bottom) / 2;
        const dx = Math.abs(right - left);
        const dy = Math.abs(bottom - top);
        let targetBB = document.body.getBoundingClientRect();
        let anchor_id;
        let targetSize = Infinity;
        const elementsWithId = document.body.querySelectorAll('[id]');
        for (let i = 0; i < elementsWithId.length; i++) {
            const el = elementsWithId[i];
            if (!createdByReact(el)) {
                continue;
            }
            const elBB = el.getBoundingClientRect();
            const elSize = elBB.width * elBB.height;
            if (
                elSize < targetSize &&
                x >= elBB.left &&
                x <= elBB.right &&
                y >= elBB.top &&
                y <= elBB.bottom &&
                dx < 2 * elBB.width &&
                dy < 2 * elBB.height
            ) {
                anchor_id = el.id;
                targetBB = elBB;
                targetSize = elSize;
            }
        }

        return {anchor_id, targetBB};
    }

    newText({x, y}) {
        const {text, setProps} = this.props;

        const {anchor_id, targetBB} = this.findAnchor(x, x, y, y);

        const newText = {
            x: (x - targetBB.left) / targetBB.width,
            y: (y - targetBB.top) / targetBB.height,
            anchor_id,
            open: true,
            entries: []
        };

        setProps({text: text.concat([newText])})
    }

    setText(edits, i) {
        const {text, setProps} = this.props;
        if (edits === null) {
            setProps({text: remove(i, 1, text)});
        }
        else {
            setProps({text: adjust(i, mergeLeft(edits), text)});
        }
    }

    setNewShape(pathData) {
        const newShape = this.newShape.current;
        if (newShape) {
            newShape.setAttribute('d', pathData);
        }
    }

    normalizedPoint(e) {
        let x;
        let y;
        const canvas = this.canvas.current;

        if (canvas && e.clientX !== undefined) {
            x = e.clientX;
            y = e.clientY;
        }
        else if (canvas && e.touches !== undefined) {
            // ignore multi-touch - just take the first touch point
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        }
        else {
            this.cancelShape(e);
            return null;
        }

        const {left, top} = canvas.getBoundingClientRect();
        return {pt: [x - left, y - top], x, y};
    }

    startShape(e) {
        const {modes} = this.props;
        const {pt, x, y} = this.normalizedPoint(e);

        if (pt && modes.indexOf('draw') !== -1) {
            this.newPts = filterPolygon([pt], BEND_TOLERANCE);
        }

        this.dragged = false;
        this.startPosition = {x, y};
        e.preventDefault();
        e.stopPropagation();
    }

    updateShape(e) {
        const {pt} = this.normalizedPoint(e);

        if (pt && this.newPts) {
            if (!this.dragged) {
                const startPt = this.newPts.filtered[0];
                const dragDistance = Math.sqrt(
                    Math.pow(pt[0] - startPt[0], 2),
                    Math.pow(pt[1] - startPt[1], 2)
                );
                if (dragDistance >= MIN_DRAW_SIZE) {
                    this.dragged = true;
                }
            }
            if (this.dragged) {
                this.newPts.addPt(pt);
                this.setNewShape(smooth(this.newPts.filtered, SMOOTHING));
            }
        }

        e.preventDefault();
        e.stopPropagation();
    }

    endShape(e) {
        const {modes} = this.props;

        if (!this.dragged && modes.indexOf('text') !== -1) {
            if (this.startPosition) {
                this.newText(this.startPosition);
            }
            this.cancelShape(e);
            return;
        }

        const canvas = this.canvas.current;
        const newShape = this.newShape.current;
        const {newPts} = this;
        if (!(canvas && newShape && newPts && modes.indexOf('draw') !== -1)) {
            return;
        }

        const {left, right, top, bottom} = newShape.getBoundingClientRect();
        const {left: xRef, top: yRef} = canvas.getBoundingClientRect();
        const {drawings, setProps} = this.props;
        const lastDrawing = drawings.length - 1;

        let anchor_id, targetBB;
        if (this.combiningShapes) {
            clearTimeout(this.combiningShapes);
            // TODO: update anchor for the combined shape?
            anchor_id = drawings[lastDrawing].anchor_id;
            targetBB = (
                anchor_id ? document.getElementById(anchor_id) : document.body
            ).getBoundingClientRect();
        }
        else {
            const anchorSpec = this.findAnchor(left, right, top, bottom);
            anchor_id = anchorSpec.anchor_id;
            targetBB = anchorSpec.targetBB;
        }
        const {left: xTarget, width: dx, top: yTarget, height: dy} = targetBB;
        const points = [newPts.filtered.map(([x, y]) => [
            (x + xRef - xTarget) / dx,
            (y + yRef - yTarget) / dy
        ])];

        const newDrawings = this.combiningShapes ?
            adjust(lastDrawing, evolve({points: append(points[0])}), drawings) :
            append({anchor_id, points}, drawings);
        setProps({drawings: newDrawings});

        this.cancelShape(e);
        this.combiningShapes = setTimeout(() => {
            this.combiningShapes = null;
        }, MULTI_SHAPE_DELAY);
    }

    cancelShape(e) {
        this.newPts = null;
        this.combiningShapes = null;
        this.startPosition = null;
        this.setNewShape('M0,0');
        e.preventDefault();
        e.stopPropagation();
    }

    renderDrawings(drawings, adding) {
        const out = drawings.map(({anchor_id, points}, i) => (
            points.map((ptsj, j) => <_Shape
                key={i + '-' + j}
                canvas={this.canvas}
                anchor_id={anchor_id}
                points={ptsj}
            />)
        ));
        if (adding) {
            out.push(<path
                key='new'
                ref={this.newShape}
                className='dash-note-new-path'
            />);
        }
        return out;
    }

    render() {
        const {
            id,
            permission,
            modes,
            drawings,
            text,
            quick_text,
            username
        } = this.props;
        const {adding} = this.state;

        const canAdd = CAN_ADD[permission];
        const showText = modes.indexOf('text') !== -1;
        const showDraw = modes.indexOf('draw') !== -1;
        const drawClass = adding ? 'dash-note-toolbar-active' : undefined;

        // Prevent body scrolling entirely during draw. Initially I tried
        // just preventDefault on touchmove events, which worked fine for
        // finger touches but somehow killed all Apple Pencil clicks.
        // The downside is this prevents 2-finger or mousewheel scrolling when
        // draw mode is active...
        // TODO: any way to re-enable that?
        if (adding) {
            addClass(document.body, 'no-scroll');
        }
        else {
            removeClass(document.body, 'no-scroll');
        }

        return modes && (
            <div id={id}>
                <div className='dash-note-toolbar'>
                    {canAdd && (showDraw || showText) && (
                        <div className={drawClass} onClick={this.toggleAdd}>
                            <Pencil />
                        </div>
                    )}
                </div>
                {canAdd && adding && <div
                    className='dash-note-adder-overlay'
                    onMouseDown={this.startShape}
                    onMouseMove={this.updateShape}
                    onMouseUp={this.endShape}
                    onTouchStart={this.startShape}
                    onTouchMove={this.updateShape}
                    onTouchEnd={this.endShape}
                    onTouchCancel={this.cancelShape}
                />}
                {showDraw && <svg
                    className='dash-note-canvas'
                    ref={this.canvas}
                >
                    {this.renderDrawings(drawings, adding)}
                </svg>}
                {showText && text.map((note, i) => <_Note
                    key={i}
                    index={i}
                    setText={this.setText}
                    username={username}
                    permission={permission}
                    quick_text={quick_text}
                    {...note}
                />)}
            </div>
        );
    }
}

function addClass(el, cls) {
    const classes = without([cls], el.className.split(' '));
    classes.push(cls);
    el.className = classes.join(' ');
}

function removeClass(el, cls) {
    el.className = without([cls], el.className.split(' ')).join(' ');
}

const reactPrivatePrefix = '__react';
const prefixLen = reactPrivatePrefix.length;
function createdByReact(el) {
    // hack to ensure we're looking at a react-managed element. The main thing
    // we want to ignore is plotly.js svg elements that have random IDs
    // assigned to them in order to connect up clip paths, as these will not
    // be stable across redraws
    const ownProps = Object.getOwnPropertyNames(el);
    for (const name of ownProps) {
        if (name.substr(0, prefixLen) === reactPrivatePrefix) {
            return true;
        }
    }
    return false;
}

Notes.displayName = 'Notes';
Notes.defaultProps = {
    permission: 'admin',
    modes: 'draw+text',
    text: [],
    drawings: []
};

Notes.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * The name we'll associate with any new entries added by the user.
     */
    username: PropTypes.string,

    /**
     * The commenting rights of the current user.
     * view: only view existing notes, no changes allowed.
     * comment: add entries to existing text notes, but no adding new notes or
     *   editing or deleting notes.
     * add: add new and comment on existing notes, but no editing or deleting.
     * self: add and comment on notes, and edit or delete your own entries.
     * admin: edit or delete anyone's entries or notes.
     */
    permission: PropTypes.oneOf(['view', 'comment', 'add', 'self', 'admin']),

    /**
     * Allowed note types, as a flag list. Values are
     * 'draw', 'text', 'draw+text', or '' to disable / hide all notes.
     */
    modes: PropTypes.oneOf(['draw', 'text', 'draw+text', '']),

    /**
     * The drawings currently on the page
     */
    drawings: PropTypes.arrayOf(PropTypes.exact({
        /**
         * The component ID this shape is associated with.
         * If omitted, this shape will be positioned relative to the page
         */
        anchor_id: PropTypes.string,

        /**
         * The points defining this shape. Should be an array of arrays of
         * number pairs: [[[x, y], [x, y], ...], ...]
         * x=0 is the left edge of the component (or page), x=1 is the right.
         * y=0 is the top edge of the component (or page), y=1 is the bottom.
         * x/y values are mostly between 0 and 1, but can extend beyond this.
         */
        points: PropTypes.arrayOf(
            PropTypes.arrayOf(
                PropTypes.arrayOf(
                    PropTypes.number
                )
            )
        ).isRequired
    })),

    /**
     * The text notes currently on the page
     */
    text: PropTypes.arrayOf(PropTypes.exact({
        /**
         * The component ID this note is associated with.
         * If omitted, this note will be positioned relative to the page
         */
        anchor_id: PropTypes.string,

        /**
         * The horizontal position of the note anchor, from 0 to 1.
         * 0 is the left edge of the component (or page), 1 is the right edge.
         */
        x: PropTypes.number,

        /**
         * The vertical position of the note anchor, from 0 to 1.
         * 0 is the top edge of the component (or page), 1 is the bottom edge.
         */
        y: PropTypes.number,

        /**
         * Is this note open?
         */
        open: PropTypes.bool,

        /**
         * The text comments associated with this note
         */
        entries: PropTypes.arrayOf(PropTypes.exact({
            /**
             * The name of the user who made this comment
             */
            username: PropTypes.string,

            /**
             * The name of the user who last edited this comment, if any
             */
            edit_username: PropTypes.string,

            /**
             * The comment itself
             */
            value: PropTypes.string.isRequired
        }))
    })),

    /**
     * Comments that can be inserted with a single click / tap.
     * Only empty comment boxes will show these options, not those with
     * existing entries.
     */
    quick_text: PropTypes.arrayOf(PropTypes.string),

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,

    /**
     * Object that holds the loading state object coming from dash-renderer
     */
    loading_state: PropTypes.shape({
        /**
         * Determines if the component is loading or not
         */
        is_loading: PropTypes.bool,
        /**
         * Holds which property is loading
         */
        prop_name: PropTypes.string,
        /**
         * Holds the name of the component that is loading
         */
        component_name: PropTypes.string
    }),
};
