// adapted from plotly.js/src/lib/polygon.js
// and plotly.js/src/components/drawing/index.js

function dot(a, b) {
    let out = 0;
    for (let i = 0; i < a.length; i++) {
        out += a[i] * b[i];
    }
    return out;
}

/**
 * Test if a segment of a points array is bent or straight
 *
 * @param pts Array of [x, y] pairs
 * @param start the index of the proposed start of the straight section
 * @param end the index of the proposed end point
 * @param tolerance the max distance off the line connecting start and end
 *      before the line counts as bent
 * @returns boolean: true means this segment is bent, false means straight
 */
function isBent(pts, start, end, tolerance) {
    const startPt = pts[start];
    const segment = [pts[end][0] - startPt[0], pts[end][1] - startPt[1]];
    const segmentSquared = dot(segment, segment);
    const segmentLen = Math.sqrt(segmentSquared);
    const unitPerp = [-segment[1] / segmentLen, segment[0] / segmentLen];

    for(let i = start + 1; i < end; i++) {
        const part = [pts[i][0] - startPt[0], pts[i][1] - startPt[1]];
        const partParallel = dot(part, segment);

        if (
            partParallel < 0 ||
            partParallel > segmentSquared ||
            Math.abs(dot(part, unitPerp)) > tolerance
        ) {
            return true;
        }
    }
    return false;
};

/**
 * Make a filtering polygon, to minimize the number of segments
 *
 * @param pts Array of [x, y] pairs (must start with at least 1 pair)
 * @param tolerance the maximum deviation from straight allowed for
 *      removing points to simplify the polygon
 *
 * @returns Object {addPt, raw, filtered}
 *      addPt is a function(pt: [x, y] pair) to add a raw point and
 *          continue filtering
 *      raw is all the input points
 *      filtered is the resulting filtered Array of [x, y] pairs
 */
export function filterPolygon(pts, tolerance) {
    const ptsFiltered = [pts[0]];
    let doneRawIndex = 0;
    let doneFilteredIndex = 0;

    function addPt(pt) {
        pts.push(pt);
        const prevFilterLen = ptsFiltered.length;
        let iLast = doneRawIndex;
        ptsFiltered.splice(doneFilteredIndex + 1);

        for(let i = iLast + 1; i < pts.length; i++) {
            if(i === pts.length - 1 || isBent(pts, iLast, i + 1, tolerance)) {
                ptsFiltered.push(pts[i]);
                if(ptsFiltered.length < prevFilterLen - 2) {
                    doneRawIndex = i;
                    doneFilteredIndex = ptsFiltered.length - 1;
                }
                iLast = i;
            }
        }
    }

    if(pts.length > 1) {
        const lastPt = pts.pop();
        addPt(lastPt);
    }

    return {
        addPt: addPt,
        raw: pts,
        filtered: ptsFiltered
    };
};

/*
 * generalized Catmull-Rom splines, per
 * http://www.cemyuksel.com/research/catmullrom_param/catmullrom.pdf
 */

// For rounding to 2 digits robustly, add half of that last digit then truncate
const halfLSB = 0.005;
function round2(v) {
    if (v === Math.round(v)) {
        return String(v);
    }
    const fullStr = String(v + (v > -halfLSB ? halfLSB : -halfLSB));
    let out = fullStr.substr(0, fullStr.indexOf('.') + 3);
    // strip 0 and . at the end of numbers
    let lastIndex = out.length - 1;
    while (lastIndex > 0 && out.charAt(lastIndex) === '0') {
        out = out.substr(0, lastIndex);
        lastIndex--;
    }
    return out.charAt(lastIndex) === '.' ? out.substr(0, lastIndex) : out;
}

const roundPt = ([x, y]) => round2(x) + ',' + round2(y);

const polyLine = pts => 'M' + pts.map(roundPt).join('L');

const qBezier = (ctrl, pt) => 'Q' + roundPt(ctrl) + ' ' + roundPt(pt);

const cBezier = (ctrl1, ctrl2, pt) => (
    'C' + roundPt(ctrl1) + ' ' + roundPt(ctrl2) + ' ' + roundPt(pt)
);

const CatmullRomExp = 0.5;
function makeTangent(prevpt, thispt, nextpt, smoothness) {
    const d1x = prevpt[0] - thispt[0];
    const d1y = prevpt[1] - thispt[1];
    const d2x = nextpt[0] - thispt[0];
    const d2y = nextpt[1] - thispt[1];
    const d1a = Math.pow(d1x * d1x + d1y * d1y, CatmullRomExp / 2);
    const d2a = Math.pow(d2x * d2x + d2y * d2y, CatmullRomExp / 2);
    const numx = (d2a * d2a * d1x - d1a * d1a * d2x) * smoothness;
    const numy = (d2a * d2a * d1y - d1a * d1a * d2y) * smoothness;
    const denom1 = 3 * d2a * (d1a + d2a);
    const denom2 = 3 * d1a * (d1a + d2a);
    return [
        [
            thispt[0] + (denom1 && numx / denom1),
            thispt[1] + (denom1 && numy / denom1)
        ], [
            thispt[0] - (denom2 && numx / denom2),
            thispt[1] - (denom2 && numy / denom2)
        ]
    ];
}

export function smooth(pts, smoothness, closed) {
    if(pts.length < 3) {
        return polyLine(pts) + (closed ? 'Z' : '');
    }

    let path = polyLine([pts[0]]);
    const pLast = pts.length - 1;

    const tangents = closed ?
        [makeTangent(pts[pLast], pts[0], pts[1], smoothness)] :
        [];
    for(let i = 1; i < pLast; i++) {
        tangents.push(makeTangent(pts[i - 1], pts[i], pts[i + 1], smoothness));
    }

    if (closed) {
        tangents.push(
            makeTangent(pts[pLast - 1], pts[pLast], pts[0], smoothness)
        );
        for(let i = 1; i <= pLast; i++) {
            path += cBezier(tangents[i - 1][1], tangents[i][0], pts[i]);
        }
        path += cBezier(tangents[pLast][1], tangents[0][0], pts[0]) + 'Z';
    } else {
        path += qBezier(tangents[0][0], pts[1]);
        for(let i = 2; i < pLast; i++) {
            path += cBezier(tangents[i - 2][1], tangents[i - 1][0], pts[i]);
        }
        path += qBezier(tangents[pLast - 2][1], pts[pLast]);
    }
    return path;
};
